import React, { useEffect } from 'react';

import { Body, H3, H5 } from '../../../../styles/fonts';
import { useCustomTranslation } from '../../../../hooks/custom-translation';

import {
  Content01,
  Row01,
  Data,
  Row02,
  Data011,
  Data021,
  LineBar,
} from './styles';

interface SocioDemographicTabProps {
  socioDemographic: {
    records: number;
    regularRfbPercentage: number;
    deathPercentage: number;
    higherIncome: {
      description: string;
      quantity: number;
      percentage: number;
    };
    generalData: {
      participationInCompaniesPercentage: number;
      higherFinancialRisk: {
        description: string;
        quantity: number;
        percentage: number;
      };
      higherEducation: {
        description: string;
        quantity: number;
        percentage: number;
      };
      higherMaritalStatus: {
        description: string;
        quantity: number;
        percentage: number;
      };
    };
    ageGroups: {
      description: string;
      quantity: number;
      percentage: number;
    }[];
  };
}

const SocioDemographicTab = ({
  socioDemographic,
}: SocioDemographicTabProps) => {
  const { tModalDescriptiveAnalysis } = useCustomTranslation(
    'modalDescriptiveAnalysis',
  );

  const AgeGroupBar = () => {
    const data = [...socioDemographic.ageGroups].sort(
      (a, b) => b.percentage - a.percentage,
    ); // Ordena do maior para o menor

    const config = {
      data,
      xField: 'percentage',
      yField: 'description',
      barWidthRatio: 0.7,
      height: 300,
      width: 250,
      animation: false,
      meta: {
        age: {
          alias: 'idade',
        },
        percentage: {
          alias: 'porcentagem',
        },
      },
    };
    return <LineBar {...config} />;
  };

  const GeneralDataBar = () => {
    const data = [
      {
        type: socioDemographic?.generalData?.higherEducation?.description,
        number: socioDemographic?.generalData?.higherEducation?.percentage,
      },
      {
        type: tModalDescriptiveAnalysis('participation_in_companies'),
        number:
          socioDemographic?.generalData?.participationInCompaniesPercentage,
      },
    ];

    const config = {
      data,
      xField: 'number',
      yField: 'type',
      barWidthRatio: 0.4,
      height: 300,
      width: 250,
      animation: false,
      meta: {
        type: {
          alias: '',
        },
        number: {
          alias: 'porcentagem',
        },
      },
    };

    useEffect(() => {
      if (socioDemographic?.generalData?.higherMaritalStatus?.description) {
        data.push({
          type: socioDemographic?.generalData?.higherMaritalStatus?.description,
          number:
            socioDemographic?.generalData?.higherMaritalStatus?.percentage,
        });
      }
    }, []);

    return <LineBar {...config} />;
  };

  const getRecordsMessage = (records: number) => {
    return records === 1
      ? tModalDescriptiveAnalysis('record')
      : tModalDescriptiveAnalysis('records');
  };

  return (
    <>
      <Content01>
        {socioDemographic && (
          <Row01>
            <Data>
              <H3>{socioDemographic.records.toLocaleString('pt-BR')}</H3>
              <Body weight="regular">
                {getRecordsMessage(socioDemographic.records)}
              </Body>
            </Data>
            {socioDemographic.higherIncome && (
              <Data>
                <H3 weight="medium">
                  {socioDemographic.higherIncome.percentage}
                  {tModalDescriptiveAnalysis('%')}
                </H3>
                <Body>{socioDemographic.higherIncome.description}</Body>
              </Data>
            )}
            <Data>
              <H3 weight="medium">
                {socioDemographic.regularRfbPercentage}
                {tModalDescriptiveAnalysis('%')}
              </H3>
              <Body>{tModalDescriptiveAnalysis('regular_rfb')}</Body>
            </Data>
            <Data>
              <H3 weight="medium">
                {socioDemographic.deathPercentage}
                {tModalDescriptiveAnalysis('%')}
              </H3>
              <Body>{tModalDescriptiveAnalysis('death')}</Body>
            </Data>
          </Row01>
        )}
        <Row02>
          <Data011>
            <H5 weight="medium">{tModalDescriptiveAnalysis('general_data')}</H5>
            <GeneralDataBar />
          </Data011>
          <Data021>
            <H5 weight="medium">{tModalDescriptiveAnalysis('age_group')}</H5>
            <AgeGroupBar />
          </Data021>
        </Row02>
      </Content01>
    </>
  );
};

export default SocioDemographicTab;
